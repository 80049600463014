<template>
  <v-form ref="formElem">
    <v-card>
      <translation-locales />

      <v-card-text>
        <v-select
          v-model="form.rule_id"
          class="mt-5"
          label="Activity Rule"
          :items="activityRuleOptions"
          item-text="label"
          item-value="value"
          outlined
        ></v-select>

        <v-select
          v-model="form.type"
          class="mt-5"
          label="Type"
          :items="typeOptions"
          item-text="title"
          item-value="value"
          outlined
        ></v-select>

        <v-text-field
          v-model="form.nicename"
          label="Display Name"
        />

        <translatable-input
          v-model="form.subject"
          label="Email Subject"
        />

        <translatable-textarea
          v-model="form.content"
          label="Email Content"
        />

        <p>This supports custom formatting:</p>
        <ul>
          <b>All activity invitation Template</b>
          <li>[[activity,schedules_at|format_date_time]] -> returns system exports date time</li>
          <li>[[invitation,code]]</li>
          <li>[[user,profile,full_name]] The full name of the activity owner.</li>
          <li>[[rule,max_participant]]</li>
          <li>[[merchant,name,en]]</li>
          <li>When just created invitation, [[participant]] is not inputted, therefore unavailable.</li>

          <b>+ reminded, accepted</b>
          <li>[[participant,full_name]], and any participant fields required when accept invitation.</li>
        </ul>
        <br>

        <br><br>

        <v-select
          v-model="form.merchant_id"
          placeholder="Merchant"
          :items="merchantOptions"
          item-text="label"
          item-value="value"
          outlined
          dense
          @change="reloadProducts"
        ></v-select>
      </v-card-text>

      <v-card-actions class="justify-center">
        <v-btn
          color="primary"
          :loading="loading"
          @click="validate"
        >
          Submit
        </v-btn>
      </v-card-actions>

      <v-alert
        v-for="msg in errors"
        :key="msg"
        color="warning"
      >
        {{ msg }}
      </v-alert>
    </v-card>
  </v-form>
</template>

<script>
import { TranslatableInput, TranslatableTextarea, TranslationLocales } from '@/components'
import { useActivityRule, useMerchant, useTranslatable } from '@/composables'
import { required } from '@core/utils/validation'
import { ref } from '@vue/composition-api'

export default {
  components: { TranslatableTextarea, TranslatableInput, TranslationLocales },

  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    disabledFields: {
      type: Array,
      default() {
        return []
      },
    },
    errors: {
      type: Array,
      default() {
        return []
      },
    },
    resource: {
      // default resource
      type: [Object, Boolean],
      default: false,
    },
  },

  setup(props, { emit }) {
    const initialForm = {
      nicename: props.resource?.nicename || '',
      type: props.resource?.type || 'activity_invitation.accepted',
      subject: useTranslatable(props.resource, 'subject'),
      content: useTranslatable(props.resource, 'content'),
      merchant_id: props.resource ? props.resource.merchant_id : null,
      rule_id: props.resource ? props.resource.rule_id : null,
    }

    const form = ref({ ...initialForm })

    const formElem = ref(null)

    const validate = () => {
      // console.log('valid?', formElem.value.validate())
      // if (formElem.value.validate()) {
      emit('submit', form.value)

      // }
    }

    const { merchantOptions, fetchMerchants } = useMerchant()
    fetchMerchants({ take: 999 })
    const { activityRuleOptions, fetchActivityRules } = useActivityRule()
    fetchActivityRules()

    const typeOptions = [
      { title: 'Landing page content for acceptence', value: 'activity_invitation.landing_page' },
      { title: 'Send Invitation to the contact once created.', value: 'activity_invitation.created' },
      { title: 'Response to user acceptence, send more info. e.g. Qr Code [[invitation,code]]', value: 'activity_invitation.accepted' },
      { title: 'Remind all accepted users to join', value: 'activity_invitation.reminded' },
    ]

    return {
      form,
      formElem,

      validate,
      required,

      merchantOptions,
      activityRuleOptions,
      typeOptions,
    }
  },
}
</script>
